.descending, .ascending{
  position: absolute;
  top: 8px;
  left: -30px;
  z-index: 100;
}
.descending{
  transform: rotate(180deg);
}

// TableHeader compoment
.search-img{
  top: 6px;
  right: 10px;
  pointer-events: none;
}